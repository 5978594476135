<template>
    <div class="container-ctn">
        <video id="vidoeBox" controls muted width="100%"></video>
        <!-- <live-player
            :video-url="address"
            live="true"
            style="width: 100%; height: 100%"
            :poster="poster"
            @timeupdate="timeupdate"
        ></live-player> -->
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import flvjs from "flv.js";
export default {
    components: {},
    name: "",
    data() {
        return {
            poster: require("@image/bigScreen/icon/sxtPoster.gif"),
            player: null,
            address: "",
            startTime: "",
            endTime: "",
            longTime: "",
            num: 0,
        };
    },
    props: {
        listAry: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {},
    created() {},
    mounted() {
        let obj = this.$route.query;
        console.log(
            "%c 🥘 obj: ",
            "font-size:20px;background-color: #465975;color:#fff;",
            obj
        );
        this.address = "";
        if (obj.url) {
            this.playVideo(obj.url);
        } else {
            this.getUrlFlv(obj).then((url) => {
                this.playVideo(url);
            });
        }
    },
    methods: {
        ...mapActions([""]),
        playVideo(url) {
            if (this.player) {
                this.clearPlayer();
            }
            this.player = flvjs.createPlayer({
                type: "flv",
                isLive: true,
                hasAudio: false,
                url: url,
            });
            this.initEvent();
        },
        initEvent() {
            let dom = document.getElementById(`vidoeBox`);
            this.player.attachMediaElement(dom);
            this.player.load();
            this.player.play();

            // 【flvjs播放器事件侦听】
            this.player.on(flvjs.Events.LOADING_COMPLETE, (res) => {
                console.log("加载完成", res);
            });
            this.startTime = this.common.getTimeYmdhms();
            console.log(
                "%c 🌯 this.startTime: ",
                "font-size:20px;background-color: #FFDD4D;color:#fff;",
                this.startTime
            );

            this.player.on(flvjs.Events.MEDIA_INFO, (res) => {
                this.endTime = this.common.getTimeYmdhms();
                console.log(
                    "%c 🍌 this.endTime: ",
                    "font-size:20px;background-color: #B03734;color:#fff;",
                    this.endTime
                );
                this.longTime =
                    new Date(this.endTime).getTime() -
                    new Date(this.startTime).getTime();
            });
            this.player.on(flvjs.Events.METADATA_ARRIVED, (res) => {
                // console.log("获取元数据", res);
            });
            this.player.on(flvjs.Events.RECOVERED_EARLY_EOF, (res) => {
                console.log("恢复早期EOF", res);
            });
            this.player.on(flvjs.Events.SCRIPTDATA_ARRIVED, (res) => {
                // console.log("获取到脚本数据", res);
            });
            this.player.on(
                flvjs.Events.ERROR,
                (errorType, errorDetail, errorInfo) => {
                    console.log("视频错误信息回调");
                    console.log("errorType:", errorType);
                    console.log("errorDetail:", errorDetail);
                    console.log("errorInfo:", errorInfo);
                }
            );

            // 【重要事件监听】http请求建立好后，该事件会一直监听flvjs实例
            this.player.on(flvjs.Events.STATISTICS_INFO, (res) => {
                // console.log("请求数据信息", res);
            });
        },
        async getUrlFlv(obj) {
            let result = await this.common.getFlvUrl({
                ID: obj.id,
            });
            let url = "";
            if (result.Data && result.Data.outUrl) {
                let str = result.Data.outUrl;
                url = `https://${str.slice(7)}`;
            } else {
                window.android.backToAndroid(`${obj.sbmc}设备故障`);
                this.common.showMsg(`${obj.sbmc}设备故障`, "warning");
            }
            return url;
        },
        clearPlayer() {
            this.player.pause();
            this.player.unload();
            this.player.detachMediaElement();
            this.player.destroy();
            this.player = null;
        },
    },
    destroyed() {},
};
</script>
<style scoped lang="scss">
.container-ctn {
    width: 100%;
    height: 100%;
    position: relative;
    background: #000;
    #vidoeBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>